<template>
  <CustomBottomSheet
    :refName="'filterSheet'"
    size="lg"
    :headerText="$t('filter')"
    :headerIcon="icon"
  >
    <div class="row">
      <CustomSelectBox
        :className="'col-md-12'"
        :id="`educationalCategoryToken`"
        :value="filterData.educationalCategoryToken"
        :options="educationalCategoryTokenOptions"
        v-on:changeValue="filterData.educationalCategoryToken = $event"
        :title="$t('EducationalCategories.select')"
        :imgName="'EducationalCategories.svg'"
      />
      <DateTimePicker
        class="col-md-6"
        :id="`filter-educationalGroupStartDateTimeFrom`"
        type="dateTime"
        :value="filterData.educationalGroupStartDateTimeFrom"
        :title="$t('general.startFrom')"
        v-on:changeValue="
          filterData.educationalGroupStartDateTimeFrom = $event.dateTime
        "
        :language="language"
      />
      <DateTimePicker
        class="col-md-6"
        :id="`filter-educationalGroupStartDateTimeTo`"
        type="dateTime"
        :value="filterData.educationalGroupStartDateTimeTo"
        :title="$t('general.startTo')"
        v-on:changeValue="
          filterData.educationalGroupStartDateTimeTo = $event.dateTime
        "
        :language="language"
      />
      <DateTimePicker
        class="col-md-6"
        :id="`filter-educationalGroupEndDateTimeFrom`"
        type="dateTime"
        :value="filterData.educationalGroupEndDateTimeFrom"
        :title="$t('general.endFrom')"
        v-on:changeValue="
          filterData.educationalGroupEndDateTimeFrom = $event.dateTime
        "
        :language="language"
      />
      <DateTimePicker
        class="col-md-6"
        :id="`filter-educationalGroupEndDateTimeTo`"
        type="dateTime"
        :value="filterData.educationalGroupEndDateTimeTo"
        :title="$t('general.endTo')"
        v-on:changeValue="
          filterData.educationalGroupEndDateTimeTo = $event.dateTime
        "
        :language="language"
      />
    </div>

    <CustomBaseEntityFilter
      :className="'row'"
      :value="filterData"
      v-on:changeValue="filterData.fillData($event)"
    />

    <div class="form-actions">
      <div class="icon-submit" @click.prevent="search">
        <img src="@/assets/images/search-icon.svg" :title="$t('search')" />
      </div>
      <div @click.prevent="closeBottomSheet('filterSheet')" class="icon-cancel">
        <img src="@/assets/images/cancel-icon.svg" :title="$t('cancel')" />
      </div>
    </div>
  </CustomBottomSheet>
</template>

<script>
import CustomBottomSheet from "./../../../../components/general/CustomBottomSheet.vue";
import CustomBaseEntityFilter from "./../../../../components/general/CustomBaseEntityFilter.vue";
import CustomSelectBox from "./../../../../components/general/CustomSelectBox.vue";
import DateTimePicker from "./../../../../components/general/DateTimePicker.vue";
import { getLanguage } from "./../../../../utils/functions";
import { getEducationalCategoriesDialog } from "./../../../../utils/dialogsOfApi";
import generalMixin from "./../../../../utils/generalMixin";
import icon from "@/assets/images/filter.svg";

export default {
  mixins: [generalMixin],
  props: {
    theFilterData: {
      type: Object,
    },
  },
  data() {
    return {
      language: getLanguage(),
      filterData: this.theFilterData,
      educationalCategoryTokenOptions: [],
      icon,
    };
  },
  components: {
    CustomBottomSheet,
    CustomBaseEntityFilter,
    CustomSelectBox,
    DateTimePicker,
  },
  methods: {
    async getEducationalCategoriesDialog() {
      this.isLoading = true;
      this.educationalCategoryTokenOptions =
        await getEducationalCategoriesDialog();
      this.isLoading = false;
    },
    search() {
      this.closeBottomSheet("filterSheet");
      this.$emit("search", this.filterData);
    },
  },
  async created() {
    this.getEducationalCategoriesDialog();
  },
};
</script>
